import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';

export interface ArchiveSourceCreateSchema {
  db_type: string;
  host: string;
  name: string;
  password?: string;
  port: number;
  ssl_mode: string;
  username: string;
  id?: number;
  dry_run?: boolean;
}

export async function getSchemas(organizationId: string, workspaceId: string, clusterId: string) {
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/metadata/schemas`
  );
  return res.data;
}

export function useSchemas(organizationId: string, workspaceId: string, clusterId: string) {
  return useQuery('schemas', async () => await getSchemas(organizationId, workspaceId, clusterId));
}

export async function getSourceDatabaseTableColumns(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  sourceId: number,
  database: string,
  table: string
) {
  const response = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/oltp/sources/${sourceId}/databases/${database}/tables/${table}/columns`
  );
  return response.data;
}

export function useSourceDatabaseTableColumns(
  organization_id: string,
  workspace_id: string,
  clusterId: string,
  sourceId: number,
  database: string,
  table: string
) {
  return useQuery(
    ['oltp-source-tables'],
    async () =>
      await getSourceDatabaseTableColumns(
        organization_id,
        workspace_id,
        clusterId,
        sourceId,
        database,
        table
      )
  );
}

export async function getSourceDatabaseTables(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  sourceId: number,
  database: string
) {
  const response = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/oltp/sources/${sourceId}/databases/${database}/tables`
  );
  return response.data;
}

export function useSourceDatabaseTables(
  organization_id: string,
  workspace_id: string,
  clusterId: string,
  sourceId: number,
  database: string
) {
  return useQuery(
    ['oltp-source-tables'],
    async () =>
      await getSourceDatabaseTables(organization_id, workspace_id, clusterId, sourceId, database)
  );
}

export async function getSourceDatabases(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  sourceId: number
) {
  const response = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/oltp/sources/${sourceId}/databases`
  );
  return response.data;
}

export function useSourceDatabases(
  organization_id: string,
  workspace_id: string,
  clusterId: string,
  sourceId: number
) {
  return useQuery(
    ['oltp-source-databases'],
    async () => await getSourceDatabases(organization_id, workspace_id, clusterId, sourceId)
  );
}

export async function createOltpArchiveSource(
  organizationId: string,
  workspaceId: string,
  cluster_id: string,
  input: ArchiveSourceCreateSchema
) {
  const response = await axios.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${cluster_id}/oltp/sources`,
    input
  );
  return response.data;
}

export function useCreateOltpArchiveSourceMutation(
  organization_id: string,
  workspace_id: string,
  cluster_id: string,
  successCallback: (data: any) => void,
  errorCallback: (err: AxiosError<{ message: string }>) => void
) {
  return useMutation(
    async (data: ArchiveSourceCreateSchema) =>
      await createOltpArchiveSource(organization_id, workspace_id, cluster_id, data),
    {
      onSuccess: successCallback,
      onError: errorCallback
    }
  );
}

export async function isSourceNameAvailable(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  sourceName: string
) {
  const response = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/oltp/sources/${sourceName}/available`
  );
  return response.data;
}

export function useIsSourceNameAvailable(
  organization_id: string,
  workspace_id: string,
  clusterId: string,
  sourceName: string
) {
  return useQuery(
    ['oltp-source-names'],
    async () => await isSourceNameAvailable(organization_id, workspace_id, clusterId, sourceName)
  );
}

export async function getSupportedSourceDatabases(
  organizationId: string,
  workspaceId: string,
  clusterId: string
) {
  const response = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/oltp/sources/supported-databases`
  );
  return response.data;
}

export function useSupportedSourceDatabases(
  organization_id: string,
  workspace_id: string,
  clusterId: string
) {
  return useQuery(
    ['oltp-supported-source-databases'],
    async () => await getSupportedSourceDatabases(organization_id, workspace_id, clusterId)
  );
}

export async function getOltpArchiveJobs(
  organization_id: string,
  workspace_id: string,
  cluster_id: string
) {
  const response = await axios.get(
    `/organizations/${organization_id}/workspaces/${workspace_id}/clusters/${cluster_id}/oltp/jobs`
  );
  return response.data;
}

export function useOltpArchiveJobs(
  organization_id: string,
  workspace_id: string,
  cluster_id: string
) {
  return useQuery(
    ['oltp-archive-jobs'],
    async () => await getOltpArchiveJobs(organization_id, workspace_id, cluster_id)
  );
}

export async function getOltpArchiveSources(
  organization_id: string,
  workspace_id: string,
  cluster_id: string
) {
  const response = await axios.get(
    `/organizations/${organization_id}/workspaces/${workspace_id}/clusters/${cluster_id}/oltp/sources`
  );
  return response.data;
}

export function useOltpArchiveSources(
  organization_id: string,
  workspace_id: string,
  cluster_id: string
) {
  return useQuery(
    ['oltp-archive-sources'],
    async () => await getOltpArchiveSources(organization_id, workspace_id, cluster_id)
  );
}

interface Table {
  source_name: string;
  sink_name: string;
  sink_database_name: string | 'default';
  replace_sink_table: boolean;
  schema: string | 'public';
  columns: Columns[];
  order_by: string[];
}

interface Columns {
  name: string;
  type: string;
  primary: boolean;
  nullable: boolean;
}

interface ArchiveJobCreateSchema {
  name: string;
  db_name: string;
  tables: Table[];
  source_id: number;
}

export async function createOltpJob(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  input: ArchiveJobCreateSchema
) {
  const response = await axios.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/oltp/jobs`,
    input
  );
  return response.data;
}

export function useCreateOltpJobMutation(
  organization_id: string,
  workspace_id: string,
  cluster_id: string,
  successCallback: (data: any) => void,
  errorCallback: (err: AxiosError<{ message: string }>) => void
) {
  return useMutation(
    async (data: ArchiveJobCreateSchema) =>
      await createOltpJob(organization_id, workspace_id, cluster_id, data),
    {
      onSuccess: successCallback,
      onError: errorCallback
    }
  );
}

export async function saveOltpArchiveSource(
  organizationId: string,
  workspaceId: string,
  cluster_id: string,
  source_id: string,
  input: ArchiveSourceCreateSchema
) {
  const response = await axios.patch(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${cluster_id}/oltp/sources/${source_id}`,
    input
  );
  return response.data;
}

export function useUpdateOltpArchiveSourceMutation(
  organization_id: string,
  workspace_id: string,
  cluster_id: string,
  source_id: string,
  successCallback: (data: any) => void,
  errorCallback: (err: AxiosError<{ message: string }>) => void
) {
  return useMutation(
    async (data: ArchiveSourceCreateSchema) =>
      await saveOltpArchiveSource(organization_id, workspace_id, cluster_id, source_id, data),
    {
      onSuccess: successCallback,
      onError: errorCallback
    }
  );
}

export async function getSSLModes(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  database: string
) {
  const res = await axios.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/oltp/sources/supported-databases/${database}/ssl-modes`
  );
  return res.data;
}

export function useSSLModes(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  database: string
) {
  return useQuery(
    'ssl-modes',
    async () => await getSSLModes(organizationId, workspaceId, clusterId, database)
  );
}

export async function deleteSource(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  sourceId: number
) {
  const res = await axios.delete(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/oltp/sources/${sourceId}`
  );
  return res.data;
}

export async function deleteJob(
  organizationId: string,
  workspaceId: string,
  clusterId: string,
  jobId: number
) {
  const res = await axios.delete(
    `/organizations/${organizationId}/workspaces/${workspaceId}/clusters/${clusterId}/oltp/jobs/${jobId}`
  );
  return res.data;
}

export async function getOltpArchiveJobDetails(
  organization_id: string,
  workspace_id: string,
  cluster_id: string,
  job_id: string
) {
  const response = await axios.get(
    `/organizations/${organization_id}/workspaces/${workspace_id}/clusters/${cluster_id}/oltp/jobs/${job_id}`
  );
  return response.data;
}

export function useOltpArchiveJobDetails(
  organization_id: string,
  workspace_id: string,
  cluster_id: string,
  job_id: string
) {
  return useQuery(
    ['oltp-archive-job-details'],
    async () => await getOltpArchiveJobDetails(organization_id, workspace_id, cluster_id, job_id)
  );
}
