import { useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';

function ConvertToGBandFormat(size: number) {
  const sizeInGb = size / (1024 * 1024 * 1024);
  return sizeInGb.toFixed(1);
}

export async function getStorageMetric(
  organization_id: string,
  workspace_id: string,
  cluster_id: string,
  timeFrame = 30
) {
  const ratio = await axios.get(
    `/organizations/${organization_id}/workspaces/${workspace_id}/clusters/${cluster_id}/metrics/storage`,
    {
      params: {
        from_timestamp: `now() - interval ${timeFrame} second`,
        to_timestamp: 'now()'
      }
    }
  );
  const sizeInByte = await axios.get(
    `/organizations/${organization_id}/workspaces/${workspace_id}/clusters/${cluster_id}/metrics/storage_max_free_used`,
    {
      params: {
        from_timestamp: `now() - interval ${timeFrame} second`,
        to_timestamp: 'now()'
      }
    }
  );

  const data = [];

  if (ratio?.data && sizeInByte?.data) {
    data.push({
      name:
        'Free' +
        `(${ConvertToGBandFormat(sizeInByte.data[0].DiskTotalMax - sizeInByte.data[0].DiskUsedMax)}GB)`,
      value: 100 - ratio.data[0].DiskUtilizationRatio
    });

    data.push({
      name: 'Utilised' + `(${ConvertToGBandFormat(sizeInByte.data[0].DiskUsedMax)}GB)`,
      value: ratio.data[0].DiskUtilizationRatio
    });
  }

  return data;
  //   const data = [
  //     {
  //       disk: 'disk-0',
  //       used_storage: 238.51618604833013,
  //       total_storage: 242.19612507674685
  //     }
  //   ];
  // const data = [
  //   { name: 'Free', value: 400 },
  //   { name: 'Utilised', value: 300 }
  // ];
  // return data;
}

export function useStorageMetric(
  organization_id: string,
  workspace_id: string,
  cluster_id: string
) {
  return useQuery(
    ['storage-metric'],
    async () => await getStorageMetric(organization_id, workspace_id, cluster_id)
  );
}

export async function getMetrics(
  organization_id: string,
  workspace_id: string,
  cluster_id: string,
  metric_type: string,
  params: {
    from_timestamp: string;
    to_timestamp: string;
    max_data_points: number;
  }
) {
  const res = await axios.post(
    `/organizations/${organization_id}/workspaces/${workspace_id}/clusters/${cluster_id}/metrics/query`,
    {
      metric_type,
      interval_seconds: 1,
      params
    }
  );
  return res.data;
}
