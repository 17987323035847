import { useQuery } from 'react-query';

import { axios } from '@/shared/utils/axios';

export async function getUserDetails(orgId: string) {
  const res = await axios.get(`/organizations/${orgId}/user_profile`);
  return res.data;
}

type UseUserDetailsOptions = {
  config?: object;
  organizationId: string;
};

export function useUserDetails({ config, organizationId }: UseUserDetailsOptions) {
  return useQuery({
    ...config,
    queryKey: ['userDetails', organizationId],
    queryFn: () => getUserDetails(organizationId)
  });
}
